<template>
  <CustomDrawer
    :value="show"
    :style-config="{
      height: '80%',
    }"
    attach=".j-cart-checkout"
    @close="onClose"
  >
    <AllDiscountDetailContent
      @close="onClose"
    />
  </CustomDrawer>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from '../../../hooks/useStore'
import CustomDrawer from '../../common/CustomDrawer.vue'
import AllDiscountDetailContent from './AllContent.vue'

const { state, commit } = useStore()
const isLoaded = ref(false)
const showDiscountDetails = computed(() => state.allDiscountDetailDrawerParams.show)
const show  = computed(() => isLoaded.value && showDiscountDetails.value)

const onClose = () => {
  commit('cart_v2/changeAllDiscountDetailDrawerParams', {
    show: false,
  })
}

onMounted(() => {
  commit('cart_v2/updateState', {
    key: 'loadingShow',
    value: false,
  })
  isLoaded.value = true
})
</script>
